import React, { useEffect, useState } from 'react';
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from 'react-toastify';
import { ApiGet, ApiPut } from '../../../helpers/API/ApiData';
import moment from "moment";


function TotalUser() {
  const [users, setUsers] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [, setloderrr] = useState(false);
  const [search, setSearch] = useState();

  
  // const[idforData,setidforData]

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    }
  }, [debouncedSearchTerm]);



 // get all users
  useEffect(() => {
    getUserData();
  }, [page, countPerPage]);

  const getUserData = async () => { 
    setLoadingData(true);
    if (!search) {
      await ApiGet(`user/get-user?user=true&page=${page}&limit=${countPerPage}`)
        .then((res) => {
          setLoadingData(false)
          setUsers(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false)
        })}
        else{
          await ApiGet(`user/get-user?user=true&letter=${search}&page=${page}&limit=${countPerPage}`)
          .then((res) => {
            setLoadingData(false)
            setUsers(res?.data?.payload?.data);
            setCount(res?.data?.payload?.count);
          })
          .catch((err) => {
            setLoadingData(false)
          })
        }
      
  };

  // active-inactive userc
  const updatestatus = (e,row) => {
    ApiPut(`admin/active-deactive?id=${row?._id}&isActive=${row?.status === "active" ? false : true}`)
      .then((res) => {
        getUserData();
        toast.success(res?.data?.messages);

      })
      .catch((error) => {
        toast.error(error?.response?.data?.messages); 
        setloderrr(false);
      });
  }

  const customStyles = {
    header: {
      style: {
        minHeight: "56px"
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  
  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "User name",
      selector: (row) => row?.userName,
      sortable: true,
    },
    {
      name: "Phone number",
      selector: (row) => row?.phoneNo,
      sortable: true,
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) => row?.status === "active" ? "active" : "inactive",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.active,
      sortable: true,

      cell: (row) => {
        return (
          <>

            <div
            className={row?.status === "active" ? " btn btn-active-color" : "btn btn-danger"}
                onClick={(e) => {
                  updatestatus(e,row);
                }}>
              {row.status === "active" ? "active" : "inactive"}
          </div>
          </>
        );
      },
      width: "15%",
    },
  ];
 
  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">User</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
            </div>
          </div>

          {loadingData ? <div
            className="d-flex justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <div class="spinner-border text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
            :
            <DataTable
              columns={columns}
              data={users}
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            />
          }
        </div>

      </div>
    </>
  )
}
export default TotalUser;
