import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";

function Usertransaction() {
  const [userwallet, setUserwallet] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();

  // const[idforData,setidforData]

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getUserData();
    }
  }, [debouncedSearchTerm]);

  // get all userwallet
  useEffect(() => {
    getUserData();
  }, [page, countPerPage]);

  const getUserData = async () => {
    setLoadingData(true);
    if (!search) {
      await ApiGet(
        `user-wallet-transaction/get?page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setLoadingData(false);
          console.log("kkkkkkk", res);
          setUserwallet(res?.data?.payload?.filterData);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
    } else {
      await ApiGet(
        `user-wallet-transaction/get?letter=${search}&page=${page}&limit=${countPerPage}`
      )
        .then((res) => {
          setLoadingData(false);
          setUserwallet(res?.data?.payload?.filterData);
          setCount(res?.data?.payload?.count);
        })
        .catch((err) => {
          setLoadingData(false);
        });
    }
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    {
      name: "User Name",
      selector: (row) => row?.userId?.userName,
      sortable: true,
    },
    {
      name: "User Amount",
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: "Phone No",
      selector: (row) => row?.aid?.phoneNo,
      sortable: true,
    },
    {
      name: "Profile Image",
      cell: (row) => {
        return (
          <>
            {row?.aid?.profileImage ? (
              <div className="mt-4 mb-4 p-1 border border-dark rounded">
                <img src={row?.aid?.profileImage} width={100} height={50} />
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "15%",
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">User Transaction</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  name="search"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height"></div>
          </div>

          {loadingData ? (
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: "20px" }}
            >
              <div class="spinner-border text-dark" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={userwallet}
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => {
                setPage(page);
              }}
              onChangeRowsPerPage={(rowPerPage) => {
                setCountPerPage(rowPerPage);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default Usertransaction;
