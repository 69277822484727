import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import { Tooltip } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import {ApiGet,ApiPost, ApiPut,ApiDelete} from "../../../../helpers/API/ApiData";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import ReactHtmlParser from "html-react-parser";


export default function Blog() {
  const [blogData, setBlogData] = useState({});
  const [addBlog, setAddBlog] = useState(false);
  const [errors, setErrors] = useState({});
  const [getAllBlog, setGetAllBlog] = useState({});
  const [isEditApi, setIsEditApi] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [blogIdForEdit, setBlogIdForEdit] = useState();
  const [blogIdForDelete, setBlogIdForDelete] = useState();
  const [countPerPage, setCountPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [loaderForGetAll, setLoaderForGetAll] = useState(false);
  const [errorsForPhoto, setErrorsForPhoto] = useState({});
  const [show, setShow] = useState(false);

  //For gell allcategory
  useEffect(() => {
    getAllBlogData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [page, countPerPage]);

  //For close category model
  const addCategoryModelClose = (e) => {
    setAddBlog(false);
    setIsEditApi(false);
    setErrors(false);
    setBlogData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setBlogData({ ...blogData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setBlogData({ ...blogData, [name]: e.target.files[0] });
    setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };

  //Form validation
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!blogData?.blogName?.trim() || blogData?.blogName === "") {
      formvalied = false;
      errors["blogName"] = "*Please enter blog Name.";
    }
    if (blogData && !blogData?.file) {
      formvalied = false;
      errors["file"] = "*plese select image.";
    }
    if (!blogData?.blogDetails?.trim() || blogData?.blogDetails === "") {
        formvalied = false;
        errors["blogDetails"] = "*Please enter blog detail.";
      }
    setErrors(errors);
    return formvalied;
  };

  const handleClose = () => {
    setShow(false);
  };

  //Api for get all companydata
  const getAllBlogData = async () => {
    setLoaderForGetAll(true);
    if (!search) {
      await ApiGet(`blog/find?page=${page}&limit=${countPerPage}`)
        .then((res) => {
          setGetAllBlog(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);

          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    } else {
      await ApiGet(`blog/find?letter=${search}&page=${page}&limit=${countPerPage} `)
        .then((res) => {
          setGetAllBlog(res?.data?.payload?.data);
          setCount(res?.data?.payload?.count);

          setLoaderForGetAll(false);
        })
        .catch((err) => {
          setLoaderForGetAll(false);
        });
    }
  };

  //Api For add category
  const addBlogData = async (e) => {
    setIsEditApi(false);
    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      formData.append("blogName", blogData?.blogName);
      formData.append("file", blogData?.file);
      formData.append("blogDetails", blogData?.blogDetails);

      await ApiPost(`blog/create`, formData)
        .then((res) => {
          toast.success("Blog add successfully");
          setAddBlog(false);
          setIsEditApi(false);
          getAllBlogData();
          setBlogData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    }
  };

  // Api for edit category Data
  const editBlogData = async (e) => {
    if (formValidation()) {
      let formData = new FormData();
      formData.append("blogName", blogData?.blogName);
      formData.append("file", blogData?.file);
      formData.append("blogDetails", blogData?.blogDetails);
      formData.append("role", "628c56134ca60c2cf0d70a24");
      setLoader(true);

      await ApiPut(`blog/update?id=${blogIdForEdit}`, formData)
        .then((res) => {
          toast.success("Blog update successfully");
          getAllBlogData();
          setAddBlog(false);
          setIsEditApi(false);
          setBlogData([]);
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    }
  };

  // For Delete category Api
  const deleteBlogData = async () => {
    await ApiDelete(`blog/delete?id=${blogIdForDelete}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Delete blog successfully");
          setShow(false);
          getAllBlogData();
        } else {
          toast.error("something went wrong please try again");
        }
      })
      .catch((err) => {
        toast.error("something went wrong please try again");
      });
  };

  //For search and pegination

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };
  const debouncedSearchTerm = useDebounce(search, 500);
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      //   setIsLoaderVisible(true);
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllBlogData();
    } else {
      setPage(1);
      setCount(0);
      setCountPerPage(countPerPage);
      getAllBlogData();
    }
  }, [debouncedSearchTerm]);

  // -------------------------DATA TABLE--------------------
  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const columns = [
    {
      name: "SNr",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "5%",
    },
    {
        name: "Blog Name",
        selector: (row) => row?.blogName,
        sortable: true,
      },
      // {
      //     name: "blog Details",
      //     selector: (row) => row?.blogDetails,
      //     sortable: true,
      //   },
        {
          name: "blog Details",
          cell: (row) => {
            return (
              <div className="showmore-class  p-2 ">
                {ReactHtmlParser(
                 row?.blogDetails?.length > 200
                    ?row?.blogDetails?.substring(0, 200) + "..."
                    :row?.blogDetails
                )}
              </div>
            );
          },
          sortable: true,
        },
    {
      name: "File",
      cell: (row) => {
        return (
          <>
            <div className="mt-4 mb-4 p-1 border border-dark rounded">
              <img src={row?.images?.[0]} width={100} height={50} />
            </div>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => {
        return (
          <>
            {/* edit category */}
            <div className="d-flex justify-content-between mr-3">
              <div
                className="cursor-pointer pl-2"
                onClick={(e) => {
                  setIsEditApi(true);
                  setAddBlog(true);
                  setBlogIdForEdit(row?._id);
                  setBlogData({
                    blogName: row?.blogName,
                    file: row?.images?.[0],
                    blogDetails: row?.blogDetails,

                  });
                }}
              >
                <Tooltip title="edit blog" arrow>
                  <CreateIcon />
                </Tooltip>
              </div>

              <div
                data-toggle="modal"
                data-target="#exampleModal"
                className="cursor-pointer"
                onClick={(e) => {
                  // deleteBlogData();
                  setBlogIdForDelete(row?._id);
                  setShow(true);
                }}
              >
                <Tooltip title="delete blog" arrow>
                  <DeleteIcon />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Blog</h2>
            </div>
            <div className="col">
              <div>
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid `}
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddBlog(true);
                }}
              >
                Add Blog
              </button>
            </div>
          </div>
        </div>
        {loaderForGetAll ? (
          <div className="text-center">
            <div className="spinner-border"></div>
          </div>
        ) : (
          //Data Table

          <DataTable
            columns={columns}
            data={getAllBlog}
            customStyles={customStyles}
            // pagination
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={count}
            paginationPerPage={countPerPage}
            paginationRowsPerPageOptions={[10, 20, 25, 50, 100]}
            paginationDefaultPage={page}
            onChangePage={(page) => {
              setPage(page);
            }}
            onChangeRowsPerPage={(rowPerPage) => {
              setCountPerPage(rowPerPage);
            }}
          />
        )}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Alarm!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Blog?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="danger" onClick={() => deleteBlogData()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Open add photographer model */}
      {addBlog === true ? (
        <Dialog fullScreen open={addBlog}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCategoryModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="ml-40">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Blog Name
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="title"
                      name="blogName"
                      value={blogData?.blogName}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["blogName"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  file
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <div className="labelMainDiv">
                      <label htmlFor="imageUpload" className="lableForImage">
                        select file
                      </label>
                      {blogData?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="file"
                      id="imageUpload"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {blogData?.file && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          blogData?.file
                            ?.toString()
                            ?.includes(".com") === true
                            ? blogData?.file
                            : URL.createObjectURL(blogData?.file)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["file"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Blog Detail
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                    //   type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="blogDetails"
                      name="blogDetails"
                      value={blogData?.blogDetails}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["blogDetails"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button className="btn btn-color" type="button">
                    {isEditApi ? "Edit  Blog" : "Add Blog"}
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-color center"
                  onClick={(e) => {
                    isEditApi ? editBlogData(e) : addBlogData(e);
                  }}
                >
                  {isEditApi ? "Edit  Blog" : "Add Blog"}
                </button>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
