import React, { useEffect, useState } from 'react';
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from 'react-toastify';
import { ApiGet } from '../../../helpers/API/ApiData';
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';

function ContactUs() {
  const [contactUs, setContactUs] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const getUserData = async () => {
    setLoadingData(true);
    const url = search
      ? `contact-us/get?letter=${search}&page=${page}&limit=${countPerPage}`
      : `contact-us/get?page=${page}&limit=${countPerPage}`;
    try {
      const res = await ApiGet(url);
      setContactUs(res?.data?.payload?.data || []);
      setCount(res?.data?.payload?.count || 0);
    } catch (err) {
      toast.error("Failed to fetch data");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [page, countPerPage, search]);


  
  const customStyles = {
    header: {
      style: { minHeight: "56px" },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  const handleViewDetails = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px",
    },
    { name: "Name", selector: (row) => row?.name, sortable: true },
    { name: "E mail", selector: (row) => row?.email, sortable: true },
    { name: "Message", selector: (row) => row?.message, sortable: true },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => handleViewDetails(row)}
        >
          View Details
        </button>
      ),
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Astrologer Contact-Us</h2>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleSearch(e)}
                value={search}
                placeholder="Search"
              />
            </div>
          </div>
          {loadingData ? (
            <div className="d-flex justify-content-center mt-4">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={contactUs}
              customStyles={customStyles}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={count}
              paginationPerPage={countPerPage}
              paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              paginationDefaultPage={page}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(rowPerPage) => setCountPerPage(rowPerPage)}
            />
          )}
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Contact Details</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              {
                console.log('selectedRow',selectedRow)
                
              }
              <div className="modal-body">
                <p><strong>Name:</strong> {selectedRow?.name}</p>
                <p><strong>Email:</strong> {selectedRow?.email}</p>
                <p><strong>Phone:</strong> {selectedRow?.userId?.phoneNo ? selectedRow?.userId?.phoneNo : '-'}</p>
                <p>
                  <strong>Created At:</strong>{" "}
                  {moment(selectedRow?.createdAt).format("Do MMM YYYY")}
                </p>
                <p><strong>Message:</strong> {selectedRow?.message}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactUs;
