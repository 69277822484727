import React, {
} from "react";
import {
  MixedWidget1,
} from "../widgets";
export function Demo1Dashboard() {

  return (
    <>
      <div className="row" >
        <div className="col-lg-12 col-xxl-12 p-0">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-4 col-xxl-4 mt-6">
          <div className="mb-4"></div>
        </div>
        <div className="col-lg-4 col-xxl-4">
        </div>
        <div className="col-lg-4 col-xxl-4">

        </div>
      </div>
    </>
  );
}
