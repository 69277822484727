import React, { useState, useEffect } from "react";
import moment from "moment";
import { ApiPut } from "../../../helpers/API/ApiData";
import { toast } from "react-toastify";
import { ApiGet } from "../../../helpers/API/ApiData";

export default function AllInformation(props) {
  const { dataViewMore, handleAllInfoClose, viewUserInfo, setViewUserInfo } =props;
  const [KycInformation, setKycInformation] = useState({});
  const [rejectKycInformation, setRejectKycInformation] = useState(false);
  const [rejectReasonData, setReajectReasonData] = useState({});
  const [errors, setErrors] = useState({});

  console.log("KycInformation", viewUserInfo);

  const adharBack =
    KycInformation[0]?.DocumentDetails?.aadharBackImage?.includes(".pdf");
  const adharFront =
    KycInformation[0]?.DocumentDetails?.aadharFrontImage?.includes(".pdf");
  const panBack =
    KycInformation[0]?.DocumentDetails?.panBackImage?.includes(".pdf");
  const panFront =
    KycInformation[0]?.DocumentDetails?.panFrontImage?.includes(".pdf");

  console.log("dataViewMore", KycInformation?.rejectReasonData);
  useEffect(() => {
    getAllKyc();
  }, []);

  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (!rejectReasonData?.reason?.trim() || rejectReasonData?.reason === "") {
      formvalied = false;
      errors["reason"] = "*Please enter reason of rejection";
    }

    setErrors(errors);
    return formvalied;
  };

  const getAllKyc = (e) => {
    ApiGet(`kyc/find?id=${dataViewMore?._id}`).then((res) => {
      console.log("kycDocument", res);
      setKycInformation(res?.data?.payload?.data);
    });
  };
  console.log("kycccccc", KycInformation);

  const accpetOrRejectKyc = (e, itam) => {
    console.log("ishaa", itam);
    if (itam === "reject") {
      if (formValidation()) {
        const data = {
          reason: itam === "Approve" ? "" : rejectReasonData?.reason,
        };

        ApiPut(
          `admin/approved-disapproved?id=${dataViewMore?._id}&type=${
            itam === "Approve" ? true : false
          }`,
          data
        )
          .then((res) => {
            setViewUserInfo(false);
            toast.success("Kyc data reject successfully");
          })
          .catch((err) => {
            toast.error("something went wrong please try again");
          });
      }
    } else {
      const data = {
        reason: itam === "Approve" ? "" : rejectReasonData?.reason,
      };

      ApiPut(
        `admin/approved-disapproved?id=${dataViewMore?._id}&type=${
          itam === "Approve" ? true : false
        }`,
        data
      )
        .then((res) => {
          setViewUserInfo(false);
          toast.success("Kyc data approved successfully");
          window.location.reload();
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
        });
    }
  };

  const rejectKyc = (e) => {
    setRejectKycInformation(true);
  };

  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setReajectReasonData({ ...rejectReasonData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  console.log("gggg", rejectReasonData);
  return (
    <>
      <div>
        {KycInformation?.length > 0 ? (
          <div className="all-info-white-banner">
            <div className="page-title-alignment">
              {/* PERSONAL INFORMATION */}
              <h3>Personal Information:</h3>
              <div className="first-box-design">
                <div className="first-box-input-grid">
                  <div className="input">
                    <label>Full Name</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.name}
                    />
                  </div>
                  <div className="input">
                    <label>Email</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.email}
                    />
                  </div>
                  <div className="input">
                    <label>Description</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.description}
                    />
                  </div>
                  <div className="input">
                    <label>Mobile</label>
                    <input
                      disabled={true}
                      type="text"
                      value={KycInformation[0]?.mobile}
                    />
                  </div>

    
                  <div className="input">
                                    <label>Skill</label>
                                    <input
                                  type="text"
                                  name="skills"
                                  disabled
                                  value={KycInformation[0]?.skills}
                              
                                />
                                 
                                 
                              </div>


                                <div className="input">
                                <label>Experience</label>
                                <input
                                  type="text"
                                  name="experience"
                                  disabled
                                  value={KycInformation[0]?.experience}
                              
                                />
                              
                              </div>

                </div>

                <span
                  style={{
                    color: "red",
                    top: "5px",
                    fontSize: "14px",
                  }}
                >
                  {errors["personalInfo"]}
                </span>
              </div>

              {/* BANK DETALS */}

              <h3 className="mt-10">Bank Information:</h3>
              <div className="first-box-design">
                <div className="first-box-input-grid">
                  <div className="input">
                    <label>Bank Name</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.bankDetails?.bname}
                    />
                  </div>
                  <div className="input">
                    <label>Bank Holder Name</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.bankDetails?.name}
                    />
                  </div>
                  <div className="input">
                    <label>Bank Account Number</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.bankDetails?.reAcNumber}
                    />
                  </div>
                  <div className="input">
                    <label>IFSC Code</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.bankDetails?.ifsc}
                    />
                  </div>
                  <div className="input">
                    <label>Branch</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.bankDetails?.branch}
                    />
                  </div>
                </div>
              </div>

              {/* DOCUMENT DETAILS */}

              <h3 className="mt-10">Document Details:</h3>
              <div className="first-box-design">
                <div className="first-box-input-grid">
                  <div className="input">
                    <label>Adhar Name</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.DocumentDetails?.aadharName}
                    />
                  </div>

                  <div className="input">
                    <label>Adhar Number</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.DocumentDetails?.aadharNumber}
                    />
                  </div>

                  <div className="input">
                    <label>Aadhar Back Image</label>
                    <div>
                      {adharBack === true ? (
                        <iframe
                          src={
                            KycInformation[0]?.DocumentDetails?.aadharBackImage
                          }
                          height="250px"
                          width="300px"
                        />
                      ) : (
                        <img
                          src={
                            KycInformation[0]?.DocumentDetails?.aadharBackImage
                          }
                          alt=""
                          height="250px"
                          width="300px"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="input">
                    <label>Aadhar Fornt Image</label>
                    <div>
                      {adharFront === true ? (
                        <iframe
                          src={
                            KycInformation[0]?.DocumentDetails?.aadharFrontImage
                          }
                          height="250px"
                          width="300px"
                        />
                      ) : (
                        <img
                          src={
                            KycInformation[0]?.DocumentDetails?.aadharFrontImage
                          }
                          alt=""
                          height="250px"
                          width="300px"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="input">
                    <label>Pan Name</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.DocumentDetails?.panName}
                    />
                  </div>

                  <div className="input">
                    <label>Pan Number</label>
                    <input
                      disabled
                      type="text"
                      value={KycInformation[0]?.DocumentDetails?.panNumber}
                    />
                  </div>

                  <div className="input">
                    <label>Pan Back Image</label>
                    <div>
                      {panBack === true ? (
                        <iframe
                          src={KycInformation[0]?.DocumentDetails?.panBackImage}
                          height="250px"
                          width="300px"
                        />
                      ) : (
                        <img
                          src={KycInformation[0]?.DocumentDetails?.panBackImage}
                          alt=""
                          height="250px"
                          width="300px"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="input">
                    <label>Pan Front Image</label>
                    <div>
                      {panFront === true ? (
                        <iframe
                          src={
                            KycInformation[0]?.DocumentDetails?.panFrontImage
                          }
                          height="250px"
                          width="300px"
                        />
                      ) : (
                        <img
                          src={
                            KycInformation[0]?.DocumentDetails?.panFrontImage
                          }
                          alt=""
                          height="250px"
                          width="300px"
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="contain-center">
                {/* Approve */}
                <div className="approve-reject-alignment">
                  {KycInformation[0]?.userId?.isVerified === "approved" ? (
                    <h1 className="btn-kyc-Reject">Approved</h1>
                  ) : KycInformation[0]?.userId?.isVerified === "rejected" ||
                    KycInformation[0]?.userId?.isVerified === !"approved" ? (
                    ""
                  ) : (
                    <button
                      className="btn-kyc-Approve"
                      onClick={(e) => accpetOrRejectKyc(e, "Approve")}
                    >
                      Approve
                    </button>
                  )}

                  {/* Reject */}
                  {KycInformation[0]?.userId?.isVerified === "rejected" ? (
                    <h1 className="btn-kyc-info">Rejected</h1>
                  ) : KycInformation[0]?.userId?.isVerified === "approved" ||
                    KycInformation[0]?.userId?.isVerified === !"rejected" ? (
                    ""
                  ) : (
                    <button
                      className="btn-kyc-Reject"
                      onClick={(e) => {
                        rejectKyc(e);
                      }}
                    >
                      Reject
                    </button>
                  )}
                </div>

                {/* submit after reject btn */}
                {rejectKycInformation === true && (
                  <>
                    <div>
                      <div>
                        <textarea
                          id="reason"
                          name="reason"
                          className="reasonod-reject-data"
                          value={rejectReasonData?.reason}
                          onChange={(e) => {
                            onhandleChange(e);
                          }}
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["reason"]}
                      </span>
                      <div className="flex-alignment">
                        <button
                          onClick={(e) => accpetOrRejectKyc(e, "reject")}
                          className="submit-kyc"
                        >
                          submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <h2 className="no-kyc-data"> No KYC Data</h2>
        )}
      </div>
    </>
  );
}
