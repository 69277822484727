import React from "react";
import { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import {
  ApiGet,
  ApiPost,
  ApiPut,
  ApiDelete,
} from "../../../../helpers/API/ApiData";
import { toast, ToastContainer } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";

export default function Notifaction() {
  const [notificationData, setNotificationData] = useState({});
  const [addBlog, setAddBlog] = useState(false);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState([]);
  const [astrologer, setAstrologer] = useState([]);
  const [selected, setSelected] = useState([]);

  console.log("DFsdfdsf",selected);

  //For close category model
  const addCategoryModelClose = (e) => {
    setAddBlog(false);
    setErrors(false);
    setNotificationData({});
  };
  const onhandleChange = (e) => {
    e.target.value.replace(/[^a-zA-Z]/g, "");
    const { name, value } = e.target;
    setNotificationData({ ...notificationData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  console.log(notificationData, "notificationData");
  //Form validation
  const formValidation = () => {
    let formvalied = true;
    let errors = {};
    if (selected?.length == 0 || !selected ) {
      formvalied = false;
      errors["userIds"] = "*Please select user";
    }
    if (!notificationData?.title?.trim() || notificationData?.title === "") {
      formvalied = false;
      errors["title"] = "*Please enter title.";
    }

    if (
      !notificationData?.message?.trim() ||
      notificationData?.message === ""
    ) {
      formvalied = false;
      errors["message"] = "*Please enter message.";
    }
    setErrors(errors);
    return formvalied;
  };
  useEffect(() => {
    getUserData();
    getAstrologerData();
  }, []);

  const getUserData = async () => {
    await ApiGet(`user/get-user?user=true`)
      .then((res) => {
        console.log("?????", res?.data?.payload?.data);
        setUserData(res?.data?.payload?.data);
      })
      .catch((err) => {});
  };

  const getFunctions = () => {
    let data = selected;
    let func = [];
    for (let i = 0; i < data?.length; i++) {
      let funcValue = data[i];
      func.push(funcValue?.value);
    }
    return func;
  };

  const getAstrologerData = async () => {
    // await ApiGet(`user/admin/get-astrologer?&page=${page}&limit=${countPerPage}`)
    await ApiGet(`user/admin/get-astrologer?limit=5000000&isAll=true`)
      .then((res) => {
        console.log("astrologerees", res);
        setAstrologer(res?.data?.payload?.data);
      })
      .catch((err) => {});
  };
  const handleOnChnageAddImg = (e) => {
    const { name } = e.target;
    setNotificationData({ ...notificationData, [name]: e.target.files[0] });
    // setErrorsForPhoto({ ...errorsForPhoto, [name]: "" });
  };
  const addBlogData = async (e) => {

    const selectedAstrologer = getFunctions()

    if (formValidation()) {
      setLoader(true);
      let formData = new FormData();
      // formData.append("userIds",selectedAstrologer);
      let fileArr = Array.from(selectedAstrologer);
      fileArr.forEach((selectedAstrologer) => {
        formData.append("userIds", selectedAstrologer);
      });
      formData.append("title", notificationData?.title);
      formData.append("message", notificationData?.message);
      formData.append("file", notificationData?.file);

      await ApiPost(`user/send-notification`, formData)
        .then((res) => {
          toast.success("Notifaction send successfully");
          setAddBlog(false);
          setSelected([]);
          setNotificationData({});
          setLoader(false);
        })
        .catch((err) => {
          toast.error("something went wrong please try again");
          setLoader(false);
        });
    }
  };

  return (
    <>
      <div className="card p-1">
        <ToastContainer />
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Notifaction</h2>
            </div>

            <div className="cus-medium-button-style button-height">
              <button
                className="btn btn-color mr-2"
                onClick={() => {
                  setAddBlog(true);
                }}
              >
                Send Notification
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Open add photographer model */}
      {addBlog === true ? (
        <Dialog fullScreen open={addBlog}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => addCategoryModelClose(e)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <List>
            <div className="ml-40">
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  select role
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className="form-control rounded-lg custom-style"
                    aria-label="Default select example"
                    name="role"
                    onChange={(e) => onhandleChange(e)}
                  >
                    <option value="user">User</option>
                    <option value="astrologer">Astrologer</option>
                  </select>
                </div>
              </div>
              {notificationData?.role === "astrologer" ? (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Astrologer
                  </label>
                  <div className="col-lg-9 col-xl-6">

                    <div className="col-lg-9 col-xl-6">
                      {astrologer?.length > 0 && (
                        <>
                        
                      <MultiSelect
                        options={astrologer?.map((e, key) => {
                          return {
                            label: e?.userName,
                            value: e?._id,
                          };
                        })}
                        value={selected}
                        name="userIds"
                        onChange={(e) => setSelected(e)}
                      />
                      </>
                      )}

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userIds"]}
                      </span>

                    </div>
                  </div>
                </div>
              ) : (
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    User
                  </label>
                  <div className="col-lg-9 col-xl-6">
                  <div className="col-lg-9 col-xl-6">
                      {userData?.length > 0 && (
                        <>
                        
                      <MultiSelect
                        options={userData?.map((e, key) => {
                          return {
                            label: e?.userName,
                            value: e?._id,
                          };
                        })}
                        value={selected}
                        name="userIds"
                        onChange={(e) => setSelected(e)}
                      />
                      </>
                      )}

                      <span
                        style={{
                          color: "red",
                          top: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {errors["userIds"]}
                      </span>

                    </div>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Title
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <input
                      type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="title"
                      name="title"
                      value={notificationData?.title}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["title"]}
                  </span>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  Message
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <textarea
                      // type="text"
                      className={`form-control form-control-lg form-control-solid `}
                      id="message"
                      name="message"
                      value={notificationData?.message}
                      onChange={(e) => {
                        onhandleChange(e);
                      }}
                    />
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["message"]}
                  </span>
                </div>
              </div>


              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label">
                  file
                </label>
                <div className="col-lg-9 col-xl-6">
                  <div>
                    <div className="labelMainDiv">
                      <label htmlFor="imageUpload" className="lableForImage">
                        select file
                      </label>
                      {notificationData?.companyLogo ? (
                        ""
                      ) : (
                        <label className="labelselectfile">
                          no file selected
                        </label>
                      )}
                    </div>
                    <input
                      type="file"
                      className={`form-control form-control-lg form-control-solid `}
                      name="file"
                      id="imageUpload"
                      hidden
                      onChange={(e) => handleOnChnageAddImg(e)}
                    />
                  </div>
                  <div>
                    {notificationData?.file && (
                      <img
                        style={{
                          height: "150px",
                          width: "270px",
                          margin: "15px 0",
                        }}
                        src={
                          notificationData?.file
                            ?.toString()
                            ?.includes(".com") === true
                            ? notificationData?.file
                            : URL.createObjectURL(notificationData?.file)
                        }
                      />
                    )}
                  </div>
                  <span
                    style={{
                      color: "red",
                      top: "5px",
                      fontSize: "12px",
                    }}
                  >
                    {errors["file"]}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {loader ? (
                <>
                  <div className="text-center">
                    <button className="btn btn-color" type="button">
                      Send Notification
                      <span
                        className="mx-3 spinner spinner-black"
                        role="status"
                      ></span>
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-color center"
                  onClick={(e) => {
                    addBlogData(e);
                  }}
                >
                  Send Notifaction
                </button>
              )}
            </div>
          </List>
        </Dialog>
      ) : null}
    </>
  );
}
