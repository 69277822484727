import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";

function Horoscope() {
  const [horoscope, setHoroscope] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editRow, setEditRow] = useState(null); // For editing
  const [showEditModal, setShowEditModal] = useState(false);

  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const getUserData = async () => {
    setLoadingData(true);

    try {
      const res = await ApiGet("horoscope/getPredictions");
      setHoroscope(res?.data?.payload || []);
      setCount(res?.data?.payload || 0);
    } catch (err) {
      toast.error("Failed to fetch data");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [page, countPerPage, search]);


  const customStyles = {
    header: {
      style: { minHeight: "56px" }
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default
        }
      }
    }
  };

  const handleViewDetails = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleEdit = (row) => {
    setEditRow(row);
    setShowEditModal(true);
  };

  const handleUpdate = async () => {
    try {
      await ApiPut(`horoscope/editPrediction/${editRow._id}`, editRow);
      toast.success("Horoscope updated successfully");
      setShowEditModal(false);
      getUserData(); // Refresh data
    } catch (err) {
      toast.error("Failed to update horoscope");
    }
  };

  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px"
    },
    { name: "ZodiacSign", selector: (row) => row?.zodiacSign, sortable: true },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "15%"
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm mx-3"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </button>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() => handleEdit(row)}
          >
            Edit
          </button>
        </>
      )
    }
  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Horoscope Prediction</h2>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleSearch(e)}
                value={search}
                placeholder="Search"
              />
            </div>
          </div>
          {loadingData ? (
            <div className="d-flex justify-content-center mt-4">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={horoscope}
              customStyles={customStyles}
              highlightOnHover
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(rowPerPage) => setCountPerPage(rowPerPage)}
            />
          )}
        </div>
      </div>

      {showModal && (
        <div
          className="modal show d-block position-absolute overflow-auto"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Horoscope Prediction Details</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              {console.log("selectedRow", selectedRow)}
              <div className="modal-body">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th>Zodiac Sign</th>
                      <td>{selectedRow?.zodiacSign}</td>
                    </tr>
                    <tr>
                      <th>Prediction Date</th>
                      <td>
                        {moment(selectedRow?.predictionDate).format(
                          "Do MMM YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>Mood of the Day</th>
                      <td>{selectedRow?.moodOfDay}</td>
                    </tr>
                    <tr>
                      <th>Lucky Colours</th>
                      <td>{selectedRow?.luckyColours}</td>
                    </tr>
                    <tr>
                      <th>Lucky Number</th>
                      <td>{selectedRow?.luckyNumber}</td>
                    </tr>
                    <tr>
                      <th>Lucky Time</th>
                      <td>{selectedRow?.luckyTime}</td>
                    </tr>
                    <tr>
                      <th>Career Prediction</th>
                      <td>{selectedRow?.careerPrediction}</td>
                    </tr>
                    <tr>
                      <th>General Prediction</th>
                      <td>{selectedRow?.generalPrediction}</td>
                    </tr>
                    <tr>
                      <th>Health Prediction</th>
                      <td>{selectedRow?.healthPrediction}</td>
                    </tr>
                    <tr>
                      <th>Love Prediction</th>
                      <td>{selectedRow?.lovePrediction}</td>
                    </tr>
                    <tr>
                      <th>Money Prediction</th>
                      <td>{selectedRow?.moneyPrediction}</td>
                    </tr>
                    <tr>
                      <th>Travel Prediction</th>
                      <td>{selectedRow?.travelPrediction}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditModal && (
        <div
          className="modal show d-block position-absolute overflow-auto"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Horoscope Prediction</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowEditModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={{ overflowY: "scroll", height: "400px" }}
              >
                <div className="form-group">
                  <label>Zodiac Sign</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={editRow?.zodiacSign || ""}
                    onChange={(e) =>
                      setEditRow({ ...editRow, zodiacSign: e.target.value })
                    }
                  />
                </div>

                {/* Prediction Date */}
                <div className="form-group">
                  <label>Prediction Date</label>
                  <input
                    type="date"
                    disabled
                    className="form-control"
                    value={
                      editRow?.predictionDate
                        ? moment(editRow.predictionDate).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) =>
                      setEditRow({ ...editRow, predictionDate: e.target.value })
                    }
                  />
                </div>

                {/* Mood of Day */}
                <div className="form-group">
                  <label>Mood of Day</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editRow?.moodOfDay || ""}
                    onChange={(e) =>
                      setEditRow({ ...editRow, moodOfDay: e.target.value })
                    }
                  />
                </div>

                {/* Lucky Colours */}
                <div className="form-group">
                  <label>Lucky Colours</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editRow?.luckyColours || ""}
                    onChange={(e) =>
                      setEditRow({ ...editRow, luckyColours: e.target.value })
                    }
                  />
                </div>

                {/* Lucky Number */}
                {console.log(editRow?.luckyNumber)}
                <div className="form-group">
                  <label>Lucky Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editRow?.luckyNumber || ""}
                    onChange={(e) =>
                      setEditRow({ ...editRow, luckyNumber: e.target.value })
                    }
                  />
                </div>

                {/* Lucky Time */}
                <div className="form-group">
                  <label>Lucky Time</label>
                  <input
                    type="text"
                    className="form-control"
                    value={editRow?.luckyTime || ""}
                    onChange={(e) =>
                      setEditRow({ ...editRow, luckyTime: e.target.value })
                    }
                  />
                </div>

                {/* Career Prediction */}
                <div className="form-group">
                  <label>Career Prediction</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={editRow?.careerPrediction || ""}
                    onChange={(e) =>
                      setEditRow({
                        ...editRow,
                        careerPrediction: e.target.value
                      })
                    }
                  />
                </div>

                {/* General Prediction */}
                <div className="form-group">
                  <label>General Prediction</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={editRow?.generalPrediction || ""}
                    onChange={(e) =>
                      setEditRow({
                        ...editRow,
                        generalPrediction: e.target.value
                      })
                    }
                  />
                </div>

                {/* Health Prediction */}
                <div className="form-group">
                  <label>Health Prediction</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={editRow?.healthPrediction || ""}
                    onChange={(e) =>
                      setEditRow({
                        ...editRow,
                        healthPrediction: e.target.value
                      })
                    }
                  />
                </div>

                {/* Love Prediction */}
                <div className="form-group">
                  <label>Love Prediction</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={editRow?.lovePrediction || ""}
                    onChange={(e) =>
                      setEditRow({ ...editRow, lovePrediction: e.target.value })
                    }
                  />
                </div>

                {/* Money Prediction */}
                <div className="form-group">
                  <label>Money Prediction</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={editRow?.moneyPrediction || ""}
                    onChange={(e) =>
                      setEditRow({
                        ...editRow,
                        moneyPrediction: e.target.value
                      })
                    }
                  />
                </div>

                {/* Travel Prediction */}
                <div className="form-group">
                  <label>Travel Prediction</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={editRow?.travelPrediction || ""}
                    onChange={(e) =>
                      setEditRow({
                        ...editRow,
                        travelPrediction: e.target.value
                      })
                    }
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowEditModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Horoscope;
