import React, { useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import { toast, ToastContainer } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";

function Horoscope() {
  const [horoscope, setHoroscope] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [count, setCount] = useState(0);
  const [countPerPage, setCountPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");


  const handleSearch = (e) => {
    let val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearch(val);
  };

  const getUserData = async () => {
    setLoadingData(true);

    try {
      const res = await ApiGet("kundli/getAllKundli");
      console.log('res?.data?.payload',res?.data?.payload);
      
      setHoroscope(res?.data?.payload || []);
      setCount(res?.data?.payload || 0);
    } catch (err) {
      toast.error("Failed to fetch data");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, [page, countPerPage, search]);


  const customStyles = {
    header: {
      style: { minHeight: "56px" }
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default
        }
      }
    }
  };


  const columns = [
    {
      name: "SNr.",
      cell: (row, index) => (page - 1) * countPerPage + (index + 1),
      width: "65px"
    },
    { name: "Name", selector: (row) => row?.name, sortable: true },
    { name: "Gender", selector: (row) => row?.gender},
    { name: "DOB", selector: (row) => row?.name},
    { name: "Birth Place", selector: (row) => row?.birthPlace },
    { name: "Birth Time", selector: (row) => row?.birthTime },
    {
      name: "Create At",
      selector: (row) => moment(row?.createdAt).format("Do MMM YYYY"),
      width: "15%"
    }

  ];

  return (
    <>
      <ToastContainer />
      <div className="card p-1">
        <div className="p-2 mb-2">
          <div className="row mb-4 pr-3">
            <div className="col d-flex justify-content-between">
              <h2 className="pl-3 pt-2">Search Kundli</h2>
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleSearch(e)}
                value={search}
                placeholder="Search"
              />
            </div>
          </div>
          {loadingData ? (
            <div className="d-flex justify-content-center mt-4">
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={horoscope}
              customStyles={customStyles}
              highlightOnHover
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(rowPerPage) => setCountPerPage(rowPerPage)}
            />
          )}
        </div>
      </div>

    </>
  );
}

export default Horoscope;
